/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    ModalBody,
} from '@jutro/components';
import messages from './PNCDPopupModalWindow.messages';
import NCDStepBackList from './PNCDTable2NCDStepBackList';
import styles from './PNCDPopupModalWindow.module.scss';
import { includes } from 'lodash';

const TABLE1_NCD_YEAR_DISCOUNT_MAP = new Map([
    ['1', '11%'],
    ['2', '27%'],
    ['3', '38%'],
    ['4', '43%'],
    ['5', '49%'],
    ['6', '55%'],
    ['7', '59%'],
    ['8', '62%'],
    ['9', '68%'],
    ['10+', '72%']
]);

const renderTable1Rows = () => {
    const rowsToRenderList = [];
    TABLE1_NCD_YEAR_DISCOUNT_MAP.forEach((discount, year) => {
        rowsToRenderList.push(
            <tr key={year}>
                <td>{year}</td>
                <td>{discount}</td>
            </tr>
        );
    });
    return rowsToRenderList;
};

const renderTable2NCDStepBackRow = (vehicle, isNewBusiness) => {
    const rowsToRenderList = [];
    let currentNCDStepBack = null;
    currentNCDStepBack = NCDStepBackList.find((obj) => {
        if (vehicle.ncdYears >= 6 && obj.title.includes('6')) {
            return obj;
        } else if (obj.title.includes(vehicle.ncdYears)) {
            return obj;
        }
        return null;
    });

    const withPNCD2ClaimCellValue = (isNewBusiness) ? currentNCDStepBack.W_PNCD_2Claim_NB : currentNCDStepBack.W_PNCD_2Claim_R;
    const withPNCDClass = (vehicle.ncdYears === 1) ? styles.blankSpace : null;
    rowsToRenderList.push(
        <tr className={styles.table2NCDYearsHeldRow}>
            <td>{currentNCDStepBack.title}</td>
            <td>{currentNCDStepBack.WO_PNCD_1Claim}</td>
            <td>{currentNCDStepBack.WO_PNCD_2Claim}</td>
            <td>{currentNCDStepBack.WO_PNCD_3Claim}</td>
            <td className={withPNCDClass}>{currentNCDStepBack.W_PNCD_1Claim}</td>
            <td className={withPNCDClass}>{withPNCD2ClaimCellValue}</td>
            <td className={withPNCDClass}>{currentNCDStepBack.W_PNCD_3Claim}</td>
        </tr>
    );
    return rowsToRenderList;
};

function PNCDPopupModalWindow(props) {
    const { data: vehicle, isOpen, handleClose } = props;

    return (
        <ModalNext className={styles.andPNCDAppendixModal} isOpen={isOpen} onRequestClose={handleClose}>
            <ModalBody>
                <div id="pncdAppendixPopupBody">
                    <div id="pncdAppendixFormContainer" className={styles.andPNCDAppendixPopUpContainer}>
                        <div className={styles.pncdAppendixHeaderContainer}>
                            <h2>{messages.pncdModalTitle.defaultMessage}</h2>
                            <p>{messages.pncdModalDiscription.defaultMessage}</p>
                        </div>
                        <div className={styles.pncdAppendixTable1Container}>
                            <h3>{messages.pncdModalTable1Header.defaultMessage}</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{messages.pncdModalTable1Column1Header.defaultMessage}</th>
                                        <th>{messages.pncdModalTable1Column2Header.defaultMessage}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTable1Rows()}
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.pncdAppendixTable2Container}>
                            <h3>{messages.pncdModalTable2Header.defaultMessage}</h3>
                            <p>{messages.pncdModalTable2Desc1.defaultMessage}</p>
                            <p>{messages.pncdModalTable2Desc2.defaultMessage}</p>
                            <div className={styles.pncdAppendixTable2}>
                                <table>
                                    <thead>
                                        <tr>
                                            <td className={styles.table2HeaderBlankSpace} />
                                            <td colSpan="3">{messages.pncdModalTable2WithoutPNCDColsHeader.defaultMessage}</td>
                                            <td colSpan="3">{messages.pncdModalTable2WithPNCDColsHeader.defaultMessage}</td>
                                        </tr>
                                        <tr>
                                            <td>{messages.pncdModalTable2Col1Header.defaultMessage}</td>
                                            <td>{messages.pncdModalTable2Col2Header.defaultMessage}</td>
                                            <td>{messages.pncdModalTable2Col3Header.defaultMessage}</td>
                                            <td>{messages.pncdModalTable2Col4Header.defaultMessage}</td>
                                            <td>{messages.pncdModalTable2Col5Header.defaultMessage}</td>
                                            <td>{messages.pncdModalTable2Col6Header.defaultMessage}</td>
                                            <td>{messages.pncdModalTable2Col7Header.defaultMessage}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <h3>{messages.pncdModalTable2NewBusinessCustomer.defaultMessage}</h3>
                            <table>
                                <tbody>
                                    {renderTable2NCDStepBackRow(vehicle, true)}
                                </tbody>
                            </table>
                            <h3>{messages.pncdModalTable2RenewingCustomer.defaultMessage}</h3>
                            <table>
                                <tbody>
                                    {renderTable2NCDStepBackRow(vehicle, false)}
                                </tbody>
                            </table>
                            <div className={styles.table2FooterNote}>
                                <p>
                                    <span>{messages.pncdModalTable2FooterNote.defaultMessage}</span>
                                    {messages.pncdModalTable2Footer.defaultMessage}
                                </p>
                            </div>
                        </div>
                        <div className={styles.pncdAppendixFooterContainer}>
                            <span>{messages.pncdModalFollowingClaimListHeader.defaultMessage}</span>
                            <ul>
                                <li>{messages.pncdModalFollowingClaimListItem1.defaultMessage}</li>
                                <li>{messages.pncdModalFollowingClaimListItem2.defaultMessage}</li>
                                <li>{messages.pncdModalFollowingClaimListItem3.defaultMessage}</li>
                                <li>{messages.pncdModalFollowingClaimListItem4.defaultMessage}</li>
                                <li>{messages.pncdModalFollowingClaimListItem5.defaultMessage}</li>
                            </ul>
                            <p>{messages.pncdModalFollowingClaimListDisclaimer1.defaultMessage}</p>
                            <p>{messages.pncdModalFollowingClaimListDisclaimer2.defaultMessage}</p>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </ModalNext>
    );
}
PNCDPopupModalWindow.propTypes = {
    data: PropTypes.shape({}),
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func.isRequired
};

PNCDPopupModalWindow.defaultProps = {
    data: {},
    isOpen: false
};

export default PNCDPopupModalWindow;
