/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './PMQuotePage_PNCD.module.scss';
import messages from './PMQuotePage.PNCD.messages';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import DocUtil from 'gw-capability-quoteandbind-common-react/util/DocUtil';
import { brandingData } from 'gw-portals-branding-js';
import { Segmentation, SegmentationOption } from 'gw-portals-segmentation-react';

const params = {
    compareBy: brandingData.BRANDING
};

const pncdImgURL = (<div className={styles.NCDPSVGIcon}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
    <circle cx="125" cy="125" r="125" className={styles.covIconLayer1}/><circle cx="125" cy="125" r="100.35" className={styles.covIconLayer2}/>
    <path d="M185.58,73.66l-.64-3.58a2.89,2.89,0,0,0-2.82-2.39H172l-5.87-13.85a8.23,8.23,0,0,0-7.4-4.81H91.24a8.23,8.23,0,0,0-7.4,4.81L78,67.69H67.88a2.89,2.89,0,0,0-2.82,2.39l-.64,3.58a2.9,2.9,0,0,0,1.92,3.28l2.29.76A2.92,2.92,0,0,1,70,82.18l-1.88,2.57a2.94,2.94,0,0,0-.55,1.72l-.15,46.05h0a2.9,2.9,0,0,0,2.88,2.89H80.91a2.88,2.88,0,0,0,2.86-2.9V121h82.46v11.49a2.88,2.88,0,0,0,2.86,2.9h10.58a2.9,2.9,0,0,0,2.87-2.9l-.15-46.05a2.94,2.94,0,0,0-.55-1.72L180,82.18a2.92,2.92,0,0,1,1.41-4.48l2.29-.76A2.9,2.9,0,0,0,185.58,73.66Z" className={styles.covIconLayer3}/>
    <path d="M79.88,96.27l-.66,3.4A2.84,2.84,0,0,0,82,103.05l14.4.2a2.85,2.85,0,0,0,2.78-2.08h0a2.84,2.84,0,0,0-2-3.5l-13.74-3.6A2.83,2.83,0,0,0,79.88,96.27Z" className={styles.covIconLayer4}/>
    <path d="M170.12,96.27l.66,3.4a2.84,2.84,0,0,1-2.74,3.38l-14.4.2a2.85,2.85,0,0,1-2.78-2.08h0a2.84,2.84,0,0,1,2-3.5l13.74-3.6A2.83,2.83,0,0,1,170.12,96.27Z" className={styles.covIconLayer5}/>
    <path d="M160.8,58.51a7.2,7.2,0,0,0-6.44-4H95.64a7.2,7.2,0,0,0-6.44,4l-10,22.59h91.68Z" className={styles.covIconLayer6}/>
    <path d="M125,105.73H105.2a1.16,1.16,0,0,0-1.11,1.49l1.63,5.49a1.16,1.16,0,0,0,1.11.83h36.34a1.16,1.16,0,0,0,1.11-.83l1.63-5.49a1.16,1.16,0,0,0-1.11-1.49Z" className={styles.covIconLayer7}/>
    <path d="M187.65,166.37v.73a4.39,4.39,0,0,1-1.61,1.78c-9.52,7.56-19,15.2-28.48,22.78-2.07,1.65-4.13,3.3-6.18,5a7.69,7.69,0,0,1-2.37,1.44H134.33c-.2-.2-.45-.1-.68-.13s-.39,0-.54.13h-1a198,198,0,0,1-38.55-5.6c-1.78-.41-2-.71-2-2.57,0-12.51,0-25,0-37.53A3.7,3.7,0,0,1,94,148.63c8.6-4.31,17.64-1.91,24.74,4a4.31,4.31,0,0,0,3,1.08c4.89-.05,9.78,0,14.66,0a15.19,15.19,0,0,1,15.25,13.52c.18,1.54-.32,2.08-2,2.09H124.77c-2.41,0-2.41,0-2.41,2.38,0,.93,0,1.87,0,2.8-.12,1.53,1.53,1.08,2.53,1.15q12.64,0,25.3,0a4.62,4.62,0,0,0,3-1c5.59-4.33,11.16-8.69,16.78-13C175.16,157.43,184.59,158.81,187.65,166.37Z" className={styles.covIconLayer8}/>
    <path d="M62.67,172.47c0-5.73,0-11.45,0-17.18,0-.9.2-1.14,1.13-1.13q9.9.06,19.8,0c.91,0,1.13.21,1.13,1.12q-.06,17.19,0,34.36c0,.91-.2,1.14-1.13,1.13q-9.9-.06-19.8,0c-.91,0-1.14-.21-1.13-1.12C62.7,183.92,62.67,178.2,62.67,172.47Z" className={styles.covIconLayer9}/></svg>
</div>
);

function PMQuotePage_PNCDSection(props) {
    const { data, handlePNCDModalOpen, submitted, hasPncdBeenSelected, setHasPncdBeenSelected, onHandlePNCDChange, isQuoteLoading, allSteps } = props;
    const translator = useTranslator();
    const [pncdValue, setPncdValue] = useState(null);
    const quoteID = _.get(data, 'quoteID.value');
    const ncdProtectionAllowed = _.get(data, 'lobData.personalMotor.coverables.pmVehicles.children[0].ncdProtectionAllowed.value');
    const policyStartDate = _.get(data, 'baseData.periodStartDate.value');
    const pncdYears = _.get(data, 'lobData.personalMotor.coverables.pmVehicles.children[0].ncdYears.value');
    const pncdCosts = _.get(data, 'lobData.personalMotor.coverables.pmVehicles.children[0].ncdCosts.amount.value', 0).toFixed(2);

    function handleChange(value) {
        setPncdValue(value);
        onHandlePNCDChange(value);
    }

    useEffect(() => {
        allSteps.forEach((step) => {
            if (((step.path === 'legal-and-info-1' && step.visited)
                || window.sessionStorage.getItem(`ncdProtection-${quoteID}`) === 'true') && ncdProtectionAllowed) {
                setHasPncdBeenSelected(true);
                setPncdValue(_.get(data, 'lobData.personalMotor.coverables.pmVehicles.children[0].ncdProtection.value'));
            }
        });
    }, [allSteps]);

    return (
        (ncdProtectionAllowed === true) && (
            <div className={styles.andCoverage}>
                <div id="yourPNCDHeader" className="gw-sub-section-title-seperator">
                    <div className={styles.andPNCDIconContainer}>
                        <div className={styles.andPNCDIcon}>
                            {pncdImgURL}
                        </div>
                        <h2 className={styles.pncdHeader}>{messages.pncdTitle.defaultMessage}</h2>
                    </div>
                </div>
                <div id="pncdOptionsContainer">
                    <p className={styles.andPncdIncluded}>{translator(messages.pncdQuestion.defaultMessage, { pncdYears: pncdYears})}</p>
                    <div className={submitted && !hasPncdBeenSelected ? `${styles.andChoice} ${styles.andChoiceRedBorder}` : styles.andChoice}>
                        <div>
                            <input
                                type="radio"
                                checked={hasPncdBeenSelected && pncdValue}
                                onChange={(e) => {
                                    handleChange(true);
                                }}
                                value={true}
                                disabled={isQuoteLoading}
                            />
                            <div className={styles.ncdTextContainer}>
                                <div className={styles.andLabel}>
                                    <p className={styles.andCoverageNameLabel}><strong>{messages.pncdYes.defaultMessage}</strong></p>
                                </div>
                                <br />
                                <p>
                                    <span>{messages.pncdAddon_1.defaultMessage}</span>
                                    <a className={styles.andStepBack} onClick={handlePNCDModalOpen}>
                                        {messages.pncdStepBack.defaultMessage}
                                    </a>
                                    <Segmentation type="brand" params={params}>
                                        <SegmentationOption is="kfi">
                                            <span>{messages.pncdAddon_2KFI.defaultMessage}</span>
                                            <a className="PMQuotePageCustomHref" href={DocUtil.getFileUrl(brandingData.BRANDING, 'PRIVATE_CAR_POLICY_DOCUMENT', policyStartDate)} target="_blank" rel="noreferrer">Private Car Policy Document.</a>
                                        </SegmentationOption>
                                        <SegmentationOption is='tya' defaultOption>
                                            <span>{messages.pncdAddon_2TYA.defaultMessage}</span>
                                        </SegmentationOption>
                                    </Segmentation>
                                </p>
                                <br />
                                <p>{messages.pncdAddon_3.defaultMessage}</p>
                                <br />
                                <div><strong>{translator(messages.pncdCost.defaultMessage, { cost: pncdCosts})}</strong></div>
                            </div>
                        </div>
                    </div>
                    <div className={submitted && !hasPncdBeenSelected ? `${styles.andChoice} ${styles.andChoiceRedBorder}` : styles.andChoice}>
                        <div>
                            <input
                                type="radio"
                                checked={hasPncdBeenSelected && pncdValue === false}
                                onChange={(e) => {
                                    handleChange(false);
                                }}
                                value={false}
                                disabled={isQuoteLoading}
                            />
                            <div className={styles.ncdTextContainer}>
                                <div className={styles.andLabel}>
                                    <p className={styles.andCoverageNameLabel}><strong>{messages.pncdNo.defaultMessage}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    );
}

PMQuotePage_PNCDSection.propTypes = {
    data: PropTypes.object,
    handlePNCDModalOpen: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    hasPncdBeenSelected: PropTypes.bool,
    setHasPncdBeenSelected: PropTypes.func,
    onHandlePNCDChange: PropTypes.func.isRequired,
    isQuoteLoading: PropTypes.bool
};

export default PMQuotePage_PNCDSection;
